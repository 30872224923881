import service from '@/utils/service.js';

//  更新预处理图片
export const uploadFile = (data,callback) => {
    return service.post('/app/upload', data, {
		headers: { "Content-Type": "multipart/form-data" },
        timeout: 500000,
        onUploadProgress: function(progressEvent) {  
            // 计算上传的百分比  
            let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);  
  
            // // 更新进度条和进度信息  
            // document.getElementById('progressBar').style.width = percentCompleted + '%';  
            // document.getElementById('progressText').textContent = percentCompleted + '%';  

            callback(percentCompleted)
        }  
    })
}

