<template>
    <div class="search">
        <div class="left">
            <el-tabs v-model="params.type">
                <el-tab-pane :label="item.label.toUpperCase()" :name="item.value" v-for="item in typeList" :key="item.value"></el-tab-pane>
            </el-tabs>
        </div>
        <div class="right">
            <el-input size="mini" v-model="params.keyword" prefix-icon="el-icon-search" placeholder="关键字搜索" clearable></el-input>
            <el-button :disabled="loading" class="loading progress-btn ml15" size="mini" type="primary" @click="createApp" v-hasPermit="'/api/app/upload'">
                
                <span v-if="loading" class="num">{{progress}}%</span>
                <span v-else>发布新应用</span>
                <div class="progress" v-if="loading" :style="progressStyle"></div>
            </el-button>
        </div>
        <input type="file" v-show="false" ref="input" @input="input">
    </div>
</template>

<script>
import { uploadFile } from '@/api/fileAPI.js';

export default {
    props: {
        open: {
            type: Boolean,
            default: false,
        },
        params: {
            type: Object,
            default(){
                return {};
            }
        }
    },
    data(){
        return {
            keyword: '',
            progress: 0,
            loading: false,
        }
    },
    computed: {
        typeList(){
            const json = this.$store.getters.configMap['whrp.app.fileType'];
            const list = [{ label: 'all' , value: ' ' }];
            try {
                const obj = JSON.parse(json);
                Object.keys(obj).forEach(key => {
                    list.push({
                        label: key,
                        value: obj[key].toString()
                    })
                })
            } catch (error) {
                console.log(error);
            }
            return list;
        },
        progressStyle(){
            return {
                transform: `translateX(${this.progress - 100}%)`
            }
        }
    },
    methods: {
        createApp(){
            this.$refs.input.click();
        },
        input(event){
            const file = event.target.files[0];
            event.target.value = '';
            this.getFile(file);
        },
        getFile(file){
            this.loading = true;
            uploadFile({ app: file },progress => {
                // console.log(progress);
                this.progress = progress;
            }).then(res => {
                this.progress = 0;
                // console.log(res);
                this.$emit('openEditPage',res);
            }).finally(() => {
                this.loading = false;

            })
        },
    },
    mounted(){
        
    },
}
</script>

<style lang="less" scoped>
.search{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left{
        display: flex;
        .prompt{
            margin-right: 15px;
        }
    }
    .right{
        display: flex;
        .btn{
            white-space: nowrap;
            border: 1px solid #ccc;
            padding: 7px 15px;
        }
        /deep/ .el-button{
            position: relative;
        }
        .progress-btn{
            width: 140px;
        }
        .progress-btn.loading{
            background-color: #2CCD70;
            border: none;
        }
    }
}

.progress{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255,255,255,.6);
}
</style>