<template>
    <div class="home" @scroll="scroll" @drop.prevent="drop" @dragover.prevent>
        <div class="home-content">
            <div class="search-container">
                <Search ref="search" @searchClick="searchClick" :open.sync="open" @openEditPage="openEditPage" :params="params"></Search>
            </div>
            <div class="content">
                <ul class="list">
                    <li v-for="(item, index) in tableData" :key="index" class="list-item">
                        <AppItem :appInfo="item" @editClick="editClick(item)"></AppItem>
                    </li>
                </ul>
                <div class="load-more" v-if="hasMore" @click="loadMoreClick">加载更多...</div>
                <div class="no-more" v-else>没有更多应用</div>
            </div>
        </div>
        <EditPage :form="form" :open.sync="open" @getTableData="getTableData"></EditPage>
    </div>
</template>

<script>
import Search from './Search.vue';
import AppItem from './AppItem.vue';
import EditPage from '@/components/editPage';
import { getAppList } from '@/api/appAPI.js';

export default {
    components: {
        AppItem,
        Search,
        EditPage
    },
    data(){
        return {
            tableData: [],
            radio: 'all',
            keyword: '',
            open: false,
            params: {
                startPos: 0,
                pageSize: 10,
                pkg: '',
                type: ' ',
                keyword: ''
            },
            form: {},
            timer: null,
            newKey: '',
            // debounceFun: debounce(this.getTableData,200)
        }
    },
    created(){
        this.getTableData();
    },
    computed: {
        hasMore(){
            return !(this.tableData.length < this.params.startPos + this.params.pageSize);
        }
    },
    watch: {
        'params.type': {
            handler(val){
                this.params.startPos = 0;
                this.getTableData();
            }
        },
        'params.keyword': {
            handler(val){
                const newKey = Math.random();
                this.newKey = newKey;
                getAppList(this.params).then(res => {
                    if(newKey === this.newKey){
                        this.tableData = res;
                    }
                })
            }
        }
    },
    methods: {
        drop(event){
            // console.log(event.dataTransfer.files[0]);
            const file = event.dataTransfer.files[0];
            this.$refs.search.getFile(file);
        },
        getTableData(){
            getAppList(this.params).then(res => {
                this.tableData = res;
            })
        },
        searchClick(){

        },
        openEditPage(data){
            console.log(data);
            this.form = this.initForm(data);
            this.open = true;
        },
        editClick(data){
            // console.log(data);
            this.form = this.initForm(data);
            this.open = true;
        },
        initForm(data){
            const form = {
                id: data.id,
                type: data.type,
                version_name: data.version_name,
                name: data.name,
                unique_name: data.unique_name,
                status: data.status,
                force_update: data.force_update,
                explain: data.explain,
                pkg: data.pkg,
                version: data.version,
                extra: data.extra && JSON.parse(data.extra) || {
                    area: []
                },
            }
            return form;
        },
        scroll(){
            if(this.timer){
                return;
            }
            if(this.tableData.length < this.params.startPos + this.params.pageSize){
                return;
            }
            const scrollHeight = this.$el.scrollHeight;
            const scrollTop = this.$el.scrollTop;
            const offsetHeight = this.$el.offsetHeight;
            const threshold = 100;      //  阈值
            if(scrollTop + threshold >= scrollHeight - offsetHeight){
                this.loadMoreClick();
            }
        },
        loadMoreClick(){
            this.timer = setTimeout(() => {
                this.params.startPos = this.tableData.length;
                getAppList(this.params).then(res => {
                    this.tableData.push(...res);
                    clearTimeout(this.timer);
                    this.timer = null;
                })
            })
        }
    },
    mounted(){
        
    },
}
</script>

<style lang="less" scoped>
.home {
    width: 100%;
    height: 100%;
    position: relative;
    padding-bottom: 10px;
    box-sizing: border-box;
    overflow: auto;
    .home-content{
        width: 80%;
        margin: 0 auto;
        .search-container{
            margin: 0 auto;
            position: sticky;
            top: 0px;
            background-color: #fff;
            height: 54px;
            z-index: 1;
        }
        .content{
            position: relative;
            .list{
                .list-item{
                    border-bottom: 1px solid #eee;
                }
            }
            .load-more{
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #337ab7;
                cursor: pointer;
            }
            .no-more{
                width: 100%;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #999;
            }
        }

    }
}

/deep/ .el-dialog__body{
    // padding-top: 0;
}
</style>