<template>
    <div class="app-item">
        <div class="logo">
            <img :src="appInfo.icon || require('@/assets/whrp.png')" alt="123123">
        </div>
        <div class="info">
            <div class="title">
                <div class="name" @click="toDetail">{{appInfo.name}}</div>
                <div class="color999">v{{appInfo.version_name}}</div>
                <div class="ml15 color999">{{appInfo.pkg}}</div>
                <div class="operate">
                    <button class="" @click="editClick" v-hasPermit="'/api/app/save'">
                        <span>编辑</span>
                        <span class="line" v-if="!hasHistory"></span>
                    </button>
                    <button class="" @click="toHistory" v-if="!hasHistory">历史</button>
                </div>
            </div>
            <div class="content color666" v-html="appInfo.explain"></div>
            <div class="bottom color999">
                <div>上传人：{{appInfo.user_name}}</div>
                <div>{{Date.dateFormat(appInfo.upload_time)}}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        appInfo: {
            type: Object,
            default(){
                return {}
            }
        },
    },
    data(){
        return {
            
        }
    },
    computed: {
        hasHistory(){
            return this.$route.name === 'History';
        }
    },
    methods: {
        editClick(){
            this.$emit('editClick');
        },
        toDetail(){
            const routeData = this.$router.resolve({
                path: `/${this.appInfo.unique_name}`
            });
            window.open(routeData.href, '_blank');
        },
        toHistory(){
            this.$router.push({
                name: 'History',
                query: {
                    id: this.appInfo.id,
                }
            })
        }
    },
    mounted(){
        
    },
}
</script>

<style lang="less" scoped>
.app-item{
    height: 154px;
    display: flex;
    align-items: center;
    padding: 10px 0;
    line-height: 1.4;
    box-sizing: border-box;
    .logo{
        width: 120px;
        height: 120px;
        border: 1px solid #ccc;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            max-width: 100%;
            max-height: 100%;
        }
    }
    .info{
        width: calc(100% - 120px);
        height: 100%;
        padding-left: 20px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        .title{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 5px;
            .name{
                display: flex;
                font-size: 18px;
                margin-right: 15px;
                cursor: pointer;
                color: #337ab7;
                font-weight: bold;
            }
            .operate{
                margin-left: auto;
                display: flex;
                button{
                    color: #337ab7;
                    display: flex;
                    align-items: center;
                }
                .line{
                    display: inline-block;
                    width: 1px;
                    height: 13px;
                    background-color: #337ab7;
                    margin: 0 10px;
                    
                }
            }
        }
        .content{
            width: 100%;
            height: 79px;
            flex-shrink: 0;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 4; /* 定义文本的行数 */
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: pre;
        }
        .bottom{
            margin-left: auto;
            display: flex;
            margin-top: 5px;
            > div{
                margin-left: 15px;
            }
        }
    }
}
.color999{
    color: #999;
}
.color666{
    color: #666;
}
</style>